import React from 'react';
import Helmet from 'react-helmet';
import { Trans, withI18n } from '@lingui/react';
import { Link } from 'gatsby';
import CookieConsent, { Cookies, getCookieConsentValue } from "react-cookie-consent";
import moment from 'moment';

// styles
import '../styles/style.scss';
import HeaderNav from './headerNav';
import GlobalContextProvider from '../context/GlobalContextProvider';
import Footer from './footer';
import { prefix } from '../i18n-config';
import FloatingBackground from './img/FloatingBackground';

const TemplateWrapper = (props) => {
  const { pageContext, pathName, children, location } = props;
  const lang = pageContext?.lang || props.lang;
  const langPrefix = prefix(lang);

  const onAcceptConsent = () => {

  }

  const onRejectConsent = () => {

  }

  const renderLines = () => {
    return (
      <div className={'lines-wrapper'}>
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
          <path d="M10.2917 13.7671C10.2917 13.8498 10.2637 13.9191 10.2075 13.9751C10.1516 14.0309 10.0821 14.0588 9.99921 14.0588C9.91643 14.0588 9.84726 14.0309 9.79171 13.9751C9.73615 13.9191 9.70837 13.8498 9.70837 13.7671V6.51714C9.70837 6.4345 9.73643 6.36527 9.79254 6.30943C9.84851 6.25346 9.91796 6.22547 10.0009 6.22547C10.0837 6.22547 10.1528 6.25346 10.2084 6.30943C10.2639 6.36527 10.2917 6.4345 10.2917 6.51714V13.7671Z" fill="#FCFCFC" fillOpacity="0.5"/>
        </svg>

        <svg width="20" height="21" viewBox="0 0 20 21" fill="none">
          <path d="M10.2917 13.7671C10.2917 13.8498 10.2637 13.9191 10.2075 13.9751C10.1516 14.0309 10.0821 14.0588 9.99921 14.0588C9.91643 14.0588 9.84726 14.0309 9.79171 13.9751C9.73615 13.9191 9.70837 13.8498 9.70837 13.7671V6.51714C9.70837 6.4345 9.73643 6.36527 9.79254 6.30943C9.84851 6.25346 9.91796 6.22547 10.0009 6.22547C10.0837 6.22547 10.1528 6.25346 10.2084 6.30943C10.2639 6.36527 10.2917 6.4345 10.2917 6.51714V13.7671Z" fill="#FCFCFC" fillOpacity="0.5"/>
        </svg>
      </div>
    );
  }

  return (
    <div className={`main-wrapper`}>
      <GlobalContextProvider>
        <Helmet defaultTitle="Interstellar Productions" titleTemplate="%s | Winter 2025">
          <meta name="og:type" content="website" />
          <meta name="og:site_name" content="Interstellar Productions" />
          <title>Interstellar Productions</title>
          <meta name="description" content="Interstellar Productions is a content aggregation platform where any individual or private entity can host tournaments, contests and gaming events." />
        </Helmet>

        <HeaderNav {...props} />

        <main className="main-content">
          { renderLines() }
          { children }
          { renderLines() }
        </main>

        {(pathName !== `${langPrefix}/`) && (
          <Footer {...props} />
        )}

        <CookieConsent
          containerClasses='cookie-consent'
          location='bottom'
          buttonText={<Trans>Accept All</Trans>}
          declineButtonText={<Trans>Reject All</Trans>}
          cookieName='ip_session'
          enableDeclineButton
          flipButtons
          disableStyles={true}
          disableButtonStyles={true}
          buttonClasses='btn btn-primary'
          declineButtonClasses='btn btn-primary'
          buttonWrapperClasses='button-wrapper'
          expires={9999}
          onAccept={onAcceptConsent}
          onDecline={onRejectConsent}
        >
          <div>
            <Trans>By accepting our Cookie Policy, you allow us to use cookies to improve your browsing experience.</Trans> <Link to="/privacy-policy"><Trans>Learn more</Trans></Link>
          </div>
        </CookieConsent>

        {(pathName !== `${langPrefix}/`) && (
          <FloatingBackground />
        )}
      </GlobalContextProvider>
    </div>
  );
}

export default withI18n()(TemplateWrapper);