import React from 'react';
import PropTypes from 'prop-types';
import { prefix } from '../../i18n-config';
import { langFromPath, languages } from '../../i18n-config';
import { Link } from 'gatsby';
import { Button } from 'react-bootstrap';
import * as styles from './styles.module.scss';
import { Trans } from '@lingui/react';
import InterstellarTextLogo from '../img/InterstellarTextLogo';
import InstagramIcon from '../img/InstagramIcon';
import TwitterIcon from '../img/TwitterIcon';
import TikTokIcon from '../img/TikTokIcon';
import YoutubeIcon from '../img/YoutubeIcon';
import moment from 'moment';


const Footer = (props) => {
  const { pageContext, data, location } = props;
  const lang = pageContext?.lang || props.lang;
  const langPrefix = prefix(lang);

  const renderLangLink = () => {
    const lang = langFromPath(location.pathname);

    const getUrlPath = () => {
      let path = '';

      if (lang === 'en') {
        path = `/${languages[1]}${location.pathname}`;
      } else {
        path = location.pathname.substr(3);
      }

      return path;
    }

    return (
      <Link
        to={getUrlPath()}
      >
        <Trans>Passer au site français.</Trans>
      </Link>
    );
  }
  /*
  <Link to={`${langPrefix}/terms-and-conditions`}><Trans>Terms</Trans></Link>
  <Link to={`${langPrefix}/privacy-policy`}><Trans>Privacy Policy</Trans></Link>
  */
 
  return (
    <div className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.leftWrapper}>
          <InterstellarTextLogo className={styles.logo} />
          <div className={styles.socialButtons}>
            <Button variant='icon' className='btn-icon'>
              <InstagramIcon />
            </Button>
            <Button variant='icon' className='btn-icon'>
              <TwitterIcon />
            </Button>
            <Button variant='icon' className='btn-icon'>
              <TikTokIcon />
            </Button>
            <Button variant='icon' className='btn-icon'>
              <YoutubeIcon />
            </Button>
          </div>
        </div>
        <div className={styles.rightWrapper}>
          <div>
            <b><Trans>Our Services</Trans></b>
            <Link to={`${langPrefix}/service-centre/showcase/photography/`}><Trans>Photography</Trans></Link>
            <Link to={`${langPrefix}/service-centre/showcase/videography/`}><Trans>Videography</Trans></Link>
            <Link to={`${langPrefix}/service-centre/showcase/software/`}><Trans>Software</Trans></Link>
          </div>
          <div>
            <b><Trans>Explore</Trans></b>
            <Link to={`${langPrefix}/service-centre/showcase`}><Trans>Showcase</Trans></Link>
            <Link to={`${langPrefix}/service-centre/pricing`}><Trans>Pricing</Trans></Link>
          </div>
          <div>
            <b><Trans>Support</Trans></b>
            <Link to={`${langPrefix}/contact-us`}><Trans>Contact Us</Trans></Link>
          </div>
        </div>
        <div className={styles.bottomWrapper}>
          <span>
            &copy; {moment().format('Y')} Interstellar Productions. <Trans>All rights reserved.</Trans>
          </span>
          <Link to={`${langPrefix}/`}><Trans>Do not sell or share my personal information.</Trans></Link>
          {renderLangLink()}
        </div>
      </div>
    </div>
  );
}

Footer.propTypes = {
  show: PropTypes.bool,
  step: PropTypes.string,
  onHide: PropTypes.func,
};


export default Footer;