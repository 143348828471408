import React from "react";

const TwitterIcon = props => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M19.8295 7.98316C19.8415 8.15675 19.8415 8.33034 19.8415 8.50553C19.8415 13.8436 15.7778 20 8.34707 20V19.9968C6.15201 20 4.00255 19.3712 2.15466 18.1857C2.47384 18.2241 2.79462 18.2433 3.1162 18.2441C4.93529 18.2457 6.70238 17.6354 8.13349 16.5114C6.4048 16.4786 4.88889 15.3515 4.35932 13.706C4.96489 13.8228 5.58885 13.7988 6.18321 13.6364C4.29853 13.2556 2.94261 11.5997 2.94261 9.67665V9.62545C3.50418 9.93823 4.13294 10.1118 4.7761 10.131C3.00101 8.9447 2.45384 6.58325 3.52578 4.73696C5.57685 7.2608 8.60306 8.79511 11.8517 8.9575C11.5261 7.55439 11.9708 6.08408 13.0204 5.09774C14.6475 3.56824 17.2065 3.64663 18.736 5.27293C19.6408 5.09454 20.5079 4.76256 21.3015 4.29219C20.9999 5.22733 20.3687 6.02168 19.5256 6.52645C20.3263 6.43206 21.1087 6.21767 21.8454 5.89049C21.3031 6.70324 20.6199 7.41119 19.8295 7.98316Z" fill="#FCFCFC"/>
  </svg>
);

export default TwitterIcon;

