import React from "react";

const InstagramIcon = props => (
  <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
    <path d="M9.99864 1.6665C7.73765 1.66651 7.45289 1.67652 6.5643 1.71696C5.67712 1.75759 5.071 1.89826 4.54112 2.10434C3.99302 2.3172 3.52805 2.60128 3.06484 3.06466C2.60129 3.52787 2.31645 3.99301 2.1029 4.54094C1.89629 5.07099 1.75707 5.67728 1.71714 6.56411C1.67738 7.45303 1.66669 7.73683 1.66669 10.0001C1.66669 12.2633 1.67686 12.5455 1.71714 13.4344C1.75794 14.3216 1.89862 14.9277 2.10453 15.4576C2.31755 16.0057 2.60146 16.4707 3.06484 16.9339C3.52787 17.3974 3.99336 17.683 4.54112 17.8958C5.07135 18.1019 5.67729 18.2426 6.5643 18.2832C7.45321 18.3237 7.73719 18.3337 10.0003 18.3337C12.2637 18.3337 12.5457 18.3237 13.4346 18.2832C14.3218 18.2426 14.9292 18.1019 15.4594 17.8958C16.0074 17.683 16.471 17.3974 16.9341 16.9339C17.3976 16.4707 17.6825 16.0055 17.896 15.4576C18.1009 14.9276 18.2417 14.3229 18.2834 13.4361C18.3233 12.5472 18.3339 12.2633 18.3339 10.0001C18.3339 7.73683 18.3233 7.45303 18.2834 6.56411C18.2417 5.67694 18.1009 5.07082 17.896 4.54094C17.6825 3.99283 17.3976 3.52787 16.9341 3.06466C16.4705 2.6011 16.0064 2.31702 15.4578 2.10434C14.9265 1.89826 14.3218 1.75759 13.4346 1.71696C12.546 1.67652 12.262 1.66651 10.0003 1.6665H9.99864ZM14.4486 4.55071C15.0007 4.55071 15.4497 4.99961 15.4497 5.55171C15.4497 6.10381 15.0007 6.55109 14.4486 6.55109C13.8966 6.55109 13.4493 6.10381 13.4493 5.55171C13.4493 4.99944 13.8966 4.55233 14.4486 4.55233V4.55071ZM10.0003 5.71936C12.3637 5.71936 14.2794 7.63665 14.2794 10.0001C14.2794 12.3635 12.3637 14.2792 10.0003 14.2792C7.63684 14.2792 5.72117 12.3635 5.72117 10.0001C5.72117 7.63665 7.63684 5.71936 10.0003 5.71936Z" fill="#FCFCFC"/>
    <path d="M10.0003 7.22217C11.5343 7.22217 12.7781 8.46578 12.7781 10C12.7781 11.5341 11.5343 12.7779 10.0003 12.7779C8.46603 12.7779 7.22241 11.5341 7.22241 10C7.22241 8.46578 8.46603 7.22217 10.0003 7.22217Z" fill="#FCFCFC"/>
</svg>
);

export default InstagramIcon;

