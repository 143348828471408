import React, { createContext, useMemo, useReducer } from "react";
// import { checkIfLoggedIn, getActiveUser, setActiveUser } from "../api/accountService";
export const GlobalStateContext = createContext();
export const GlobalDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_THEME':
      if (typeof window !== 'undefined') {
        localStorage.setItem('ip_dark_theme', !state.isDarkTheme);
      }

      return {
        ...state,
        isDarkTheme: !state.isDarkTheme
      }
      break;
    case 'TOGGLE_NAV':
      if (typeof window !== 'undefined') {
        localStorage.setItem('ip_nav_oppened', !state.isNavOpened);
      }

      return {
        ...state,
        isNavOpened: !state.isNavOpened
      }
      break;
    case 'SET_USER':
      const user = action.currentUser;
      // if (user) {
      //   setActiveUser({
      //     id: user.id,
      //     email: user.email,
      //     username: user.username,
      //     firstname: user.firstname,
      //     lastname: user.lastname,
      //     isDevTeam: user.isDevTeam
      //   });
      // }

      return {
        ...state,
        currentUser: user
      }
      break;
    case 'SHOW_COMING_SOON':
      return {
        ...state,
        showComingSoonPopup: true
      }
      break;
    case 'OPEN_EMAIL_DIALOG':
      location.href="mailto:"+action.to+"?subject="+action.subject;
      return {
        ...state
      }
      break;
    case 'SHOW_LOGIN':
      return {
        ...state,
        showLoginPopup: true
      }
      break;
    case 'SHOW_REGISTRATION':
      return {
        ...state,
        showRegistrationPopup: true
      }
      break;
    case 'HIDE_POPUP':
      return {
        ...state,
        showLoginPopup: false,
        showRegistrationPopup: false,
        showComingSoonPopup: false
      }
      break;
    case 'SET_PROMO_CODE':
      // if (typeof window !== 'undefined') {
      //   localStorage.setItem('ip_promo', action.promoCode);
      // }
      // setCookie('ip_promo', action.promoCode);

      return {
        ...state,
        promoCode: action.promoCode
      }
      break;
    case 'SET_ONMOBILE':
      return {
        ...state,
        onMobile: action.onMobile
      }
      break;
    case 'SET_FULLSCREEN':
      return {
        ...state,
        onFullscreen: action.onFullscreen
      }
      break;
    case 'SET_BOOKING_INFO':
      const data = {...state.bookingInfo, ...action.bookingInfo};

      if (typeof window !== 'undefined') {
        localStorage.setItem('ip_booking_info', JSON.stringify(data));
      }

      return {
        ...state,
        bookingInfo: data
      }
      break;
    case 'RESET_BOOKING_INFO':
      if (typeof window !== 'undefined') {
        localStorage.setItem('ip_booking_info', null);
      }

      return {
        ...state,
        bookingInfo: null
      }
      break;
    case 'SET_CLIENT_INFO':
      if (typeof window !== 'undefined') {
        localStorage.setItem('ip_client', JSON.stringify(action.client));
      }

      return {
        ...state,
        client: action.client
      }
      break;
    default:
      break;
  }
}

const GlobalContextProvider = ({children}) => {
  const initialState = useMemo(() => {
    return {
      currentUser: null,
      client: (typeof window !== 'undefined') && localStorage?.getItem('ip_client') ?  JSON.parse(localStorage.getItem('ip_client')) : null,
      isDarkTheme: (typeof window !== 'undefined') && localStorage?.getItem('ip_dark_theme') === 'true' ? true : false,
      isNavOpened: (typeof window !== 'undefined') && localStorage?.getItem('ip_nav_oppened') === 'false' ? false : true,
      showLoginPopup: false,
      showComingSoonPopup: false,
      showRegistrationPopup: false,
      bookingInfo: (typeof window !== 'undefined') && localStorage?.getItem('ip_booking_info') ?  JSON.parse(localStorage.getItem('ip_booking_info')) : null,
      promoCode: null
    }
  }, []);
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
}

export default GlobalContextProvider;